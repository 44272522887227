import { FaGithub, FaLinkedin, FaStackOverflow } from "react-icons/fa";

type Props = {};

const Footer = (props: Props) => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", flexDirection: "column", paddingBottom: 10 }}
      className="footer"
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a className="glassIco" href="https://www.linkedin.com/in/pettiboy/">
          <FaLinkedin />
        </a>
        <a className="glassIco" href="https://github.com/pettiboy">
          <FaGithub />
        </a>
        <a
          className="glassIco"
          href="https://stackoverflow.com/users/14225169/hussain-pettiwala"
        >
          <FaStackOverflow />
        </a>
      </div>
      <p style={{ paddingBottom: 20 }}>© 2022-2099 Hussain Pettiwala</p>
    </div>
  );
};

export default Footer;
