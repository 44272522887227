import "./hackathonbadge.css";

import { BsFillTrophyFill } from "react-icons/bs";
import { FaMedal } from "react-icons/fa";

export type HackathonBadgeType = {
  icon: string;
  text: string;
};

const HackathonBadge = ({ icon, text }: HackathonBadgeType) => {
  return (
    <div className="hackathon-badge-container glass-effect-1">
      <div className="hackathon-badge-icon">
        {icon === "trophy" && <BsFillTrophyFill />}
        {icon === "medal" && <FaMedal />}
      </div>
      <div className="hackathon-badge-text">{text}</div>
    </div>
  );
};

export default HackathonBadge;
