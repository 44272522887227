const workExpData = [
  {
    companyLogo: process.env.PUBLIC_URL + "/logos/coindcx.png",
    companyName: "CoinDCX",
    companyTime: "July 2024 - Present",
    role: "Software Development Engineer",
    description: (
      <ul>
        <li>
          Built self serve Dashboard for Okto SDK using NextJS + Typescript
        </li>
        <li>
          Currently building the <a href="https://www.okto.tech/">Okto Chain</a>{" "}
          backend
        </li>
      </ul>
    ),
    link: "https://coindcx.com/",
  },
  {
    companyLogo: process.env.PUBLIC_URL + "/logos/edelweiss.png",
    companyName: "Edelweiss Global Markets",
    companyTime: "January 2024 - July 2024",
    role: "Software Development Engineer",
    description: (
      <ul>
        <li>
          Implemented a Python library to <b>facilitate data integration</b>{" "}
          between C++ structures and systems.
        </li>
        <li>
          <b>Developed</b> a <b>TCP client</b> in C++ to automate test order
          management, enhancing testing efficiency for trading systems;
          integrated with internal servers for operational validation.
        </li>
      </ul>
    ),
    link: "https://www.edelweissfin.com/",
  },
  {
    companyLogo: process.env.PUBLIC_URL + "/logos/rudra.webp",
    companyName: "RUDRA Cybersecurity",
    companyTime: "January 2022 - June 2023",
    role: "Frontend Developer",
    description: (
      <ul>
        <li>
          Participated and contributed in <b>product design</b> discussions to
          build an in-depth business dashboard for an intrusion detection system
          in the maritime industry from scratch.
        </li>
        <li>
          <b>Integrated</b> the front-end with REST API and GraphQL along with
          client side routing, global state management, and client side caching
          for seamless user experience.
        </li>
        <li>
          Collaborated with security professionals to understand the working,
          architecture and flow of data throughout the system to better test and
          <b> simulate end user experience.</b>
        </li>
      </ul>
    ),
    link: "https://rudrasecure.com/",
  },
  {
    companyLogo: "https://chefcities.com/logos/gold-fill-690.png",
    companyName: "ChefCities.com",
    companyTime: "November 2020 - December 2021",
    role: "Chief Technical Officer",
    description: (
      <ul>
        <li>
          Collaborated with business professionals to conduct{" "}
          <b>market research and design a product</b> to provide an online food
          ordering and delivery platform for Home Chefs.
        </li>
        <li>
          <b>Built</b> a full stack web app to create and manage orders,
          <b>automate deliveries</b> using APIs from a third party delivery
          provider, and <b>collect payments and settle</b> them using Razorpay's
          APIs.
        </li>
        <li>
          Optimising for customer demand built a{" "}
          <b>cross platform mobile application</b> using React Native with push
          notifications backed by a robust REST API built using Django Rest
          Framework with MySQL.
        </li>
      </ul>
    ),
    link: "https://chefcities.com",
  },
  {
    companyLogo: process.env.PUBLIC_URL + "/logos/cashmap.png",
    companyName: "cashmap.club",
    companyTime: "April 2021 - May 2021",
    role: "Full Stack Developer",
    description: (
      <ul>
        <li>
          Built a REST API with<b>socket io integration</b> on Express JS to
          provide real time chat experience to end users.
        </li>
        <li>
          <b>Modelled SQL</b> data schema for the app and performed CRUD
          operations on the same.
        </li>
        <li>
          Built <b>robust authentication</b> and routing for cross-platform
          mobile app using React Native.
        </li>
      </ul>
    ),
  },
];

export default workExpData;
