import React from "react";
import "./ButtonGlass.css";

type Props = {
  href?: string;
  icon?: React.ReactNode;
  height?: string | number;
  width?: string | number;
  text?: string;
  onClick?: () => void;
};

const ButtonGlass = ({ icon, href, height, width, text, onClick }: Props) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    event.preventDefault();
    onClick && onClick();
  };

  if (onClick) {
    return (
      <button
        className="glassIcoSmall glass-effect-2"
        onClick={handleClick}
        style={{ height: height, width: width }}
      >
        {icon && <div className="icon">{icon}</div>}
        <div className="glass-btn-text">{text && text}</div>
      </button>
    );
  }

  return (
    <a
      className="glassIcoSmall glass-effect-2"
      href={href}
      rel="noopener noreferrer"
      target="_blank"
      style={{ height: height, width: width }}
    >
      {icon && <div className="icon">{icon}</div>}
      <div className="glass-btn-text">{text && text}</div>
    </a>
  );
};

export default ButtonGlass;
