import React, { useRef, useEffect, useState, useContext } from 'react';
import TravelMap from './TravelMap/TravelMap';
import { Scene } from 'react-scrollmagic';
import ScrollSpy from 'react-ui-scrollspy';
import { locationCardData } from './data';
import { MapContext } from '../../contexts/MapContext';
import ResponsiveLocationCard from './LocationCard/ResponsiveLocationCard';
import Footer from '../Footer/Footer';
import { Parallax } from 'react-scroll-parallax';
import useWindowDimensions from '../../hooks/useWindowDimentions';

const containerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  // width: '100%',
};

const mapStyle: React.CSSProperties = {
  height: '30vh',
  width: '100%',
  // backgroundColor: 'lightblue',
  // display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
  position: 'sticky',
  top: 0,
  zIndex: 1,
};

const cardContainerStyle: React.CSSProperties = {
  flex: 1,
  // width: '100%',
  overflowY: 'scroll',
  overflowX: 'hidden',
  // padding: '20px',
};


const ResponsiveMapContainer: React.FC = () => {
  const { visualViewportWidth, width } = useWindowDimensions();
  const mapRef = useRef<HTMLDivElement>(null);
  const cardContainerRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);

  const { updateFlyToLocationData } = useContext(MapContext)

  const handleScroll = () => {
    if (mapRef.current && cardContainerRef.current) {
      const mapContainer = mapRef.current;
      const cardContainer = cardContainerRef.current;

      const mapRect = mapContainer.getBoundingClientRect();
      const cardRect = cardContainer.getBoundingClientRect();

      const isMapInView = mapRect.top >= 0 && mapRect.bottom <= window.innerHeight;
      const isMapSticky = isMapInView && cardRect.height > window.innerHeight - mapRect.height;

      setIsSticky(isMapSticky);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div style={containerStyle}>
      <Parallax
        translateX={[-visualViewportWidth + "px", 0 + "px", "easeOutQuint"]}
        style={{ display: "flex", marginBottom: width > 800 ? 50 : 0 }}
      >
        <h1 className="thumbsup">Honours and Awards</h1>
      </Parallax>
      <div style={{ width: "100%" }}>
        <div ref={mapRef} style={isSticky ? { ...mapStyle, position: 'sticky' } : mapStyle}>
          {/* <div className="map-content">Random Map Content</div> */}
          <div
            style={{
              width: "100vw",
              height: "30vh",
            }}>
            <TravelMap height='30vh' width='100vw' borderRadius={0} />
          </div>
        </div>
        <div ref={cardContainerRef} style={cardContainerStyle}>
          <div>
            <div>
              {locationCardData.map(({ id }, index) => (
                <div key={id + index} data-to-scrollspy-id={id}></div>
              ))}
            </div>
            <ScrollSpy onUpdateCallback={(id) => {
              const locationData = locationCardData.find((cardData) => cardData.id === id);
              if (locationData) {
                updateFlyToLocationData({
                  latitude: locationData.latitude,
                  longitude: locationData.longitude,
                  zoom: locationData.zoom,
                })
              }
            }} updateHistoryStack={false}>
              {locationCardData.map((cardData, index) => (
                <Scene key={cardData.id + index} duration={500}>
                  <div id={cardData.id} className='location-card-outer-container'>
                    <ResponsiveLocationCard
                      heading={cardData.heading}
                      initialSlide={cardData.initialSlide}
                      content={cardData.content}
                      media={cardData.media}
                      speed={cardData.speed}
                      badges={cardData.badges}
                    />
                  </div>
                </Scene>
              ))}
            </ScrollSpy>
          </div>
        </div>
      </div>

      <Footer />

    </div>
  );
};

export default ResponsiveMapContainer;
