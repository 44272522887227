export const defaultInitialView = {
  latitude: 19.076,
  longitude: 72.8777,
  zoom: 3,
};

export const mapBoxGLAccessToken =
  "pk.eyJ1IjoibWJtcGgiLCJhIjoiY2tya2F0OTJvMGk1YjJwbGZ1bDJ1eGU0dCJ9.fLJp01SsIpdhGmWdBzaSnQ";

export const mapStyle = "mapbox://styles/mbmph/clo3g8uq100l301r2ht8e6c79";
