export const locationCardData = [
  {
    id: "sih22",
    heading: "Smart India Hackathon (2022)",
    initialSlide: 1,
    content: (
      <div>
        <section>
          <h3>My 1st National Level Hackathon</h3>
          <p>
            With some of the most amazing people at the KPR Institute of
            Technology, Coimbatore.
          </p>
        </section>

        <section>
          <h3>The Project</h3>
          <p>
            Accompanied by Harshal Dave, Gautam Vishwakarma, Soham Bhoir,
            Kanishk Sharma, and Sargundeep Sachdeo, we built "Highlight" -
            Highlighting skillful interns for recruiters. Highlight on-boards
            companies and colleges on its platform. To ease the recruitment
            process, students who apply for internships are listed on the
            company's portal in the order of their compatibility.
          </p>
        </section>

        <section>
          <h3>Awards and Honors</h3>
          <p>
            Being a SIH finalist and landing in the top 5 was an incredible
            honor.
          </p>
        </section>

        <section>
          <h3>Post-Hackathon Experience</h3>
          <p>After the Hackathon, we enjoyed the mesmerizing beauty of Ooty.</p>
        </section>
      </div>
    ),
    media: [
      process.env.PUBLIC_URL + "/travel/sih22/campus",
      process.env.PUBLIC_URL + "/travel/sih22/prize",
      process.env.PUBLIC_URL + "/travel/sih22/group",
      process.env.PUBLIC_URL + "/travel/sih22/plane-home",
      process.env.PUBLIC_URL + "/travel/sih22/ooty-boat",
      process.env.PUBLIC_URL + "/travel/sih22/ooty-view",
    ],
    badges: [
      {
        icon: "trophy",
        text: "Top 5 Finalist",
      },
    ],
    latitude: 11.078965,
    longitude: 77.141846,
    zoom: 5,
    speed: 15,
  },
  {
    id: "junction-asia",
    heading: "Junction Asia",
    initialSlide: 1,
    content: (
      <div className="junction-asia-section">
        <>
          <div className="junction-asia-section">
            <p>
              Participated in Junction Asia 2022 with my friend Harshal Dave,
              representing India. We were among the top 300 finalists worldwide
              for a 24-hour hackathon in Busan, South Korea.
            </p>
            <h3>Mission: Combating Counterfeit Products</h3>
            <p>
              We aimed to address the issue of counterfeit products in
              e-commerce using Cosmos SDK and Kepler Wallet. We created a
              blockchain-based platform for artisans, ensuring transparent and
              immutable transactions with NFTs as certificates of authenticity.
              QR codes verified products.
            </p>
            <h3>More Than a Hackathon</h3>
            <p>
              This journey was a life highlight, inspiring innovation and
              positive change.
            </p>
            <h3>Acknowledgments and Gratitude</h3>
            <p>
              Thanks to our remarkable team, including Harshal Dave, Sohee Lee,
              Joy Kim & Kim Kyoung Duck, for an unforgettable collaboration.
            </p>
            <p>
              Gratitude to Team Junction for sponsorship and mentor Josh Lee for
              guidance in ideation and technical implementation.
            </p>
            <h3>Embracing the Future</h3>
            <p>
              Here's to cherishing unforgettable experiences and embracing
              future adventures!
            </p>
          </div>
        </>
      </div>
    ),
    media: [
      process.env.PUBLIC_URL + "/travel/junction-asia/2",
      process.env.PUBLIC_URL + "/travel/junction-asia/1",
      process.env.PUBLIC_URL + "/travel/junction-asia/3",
      process.env.PUBLIC_URL + "/travel/junction-asia/4",
      process.env.PUBLIC_URL + "/travel/junction-asia/5",
      process.env.PUBLIC_URL + "/travel/junction-asia/6",
    ],
    badges: [
      {
        icon: "medal",
        text: "Top 300 Finalist",
      },
    ],
    latitude: 35.169,
    longitude: 129.136,
    zoom: 5,
    speed: 20,
  },
  {
    id: "bit-n-build",
    heading: "Bit N Build",
    initialSlide: 1,
    content: (
      <div>
        <section>
          <p>
            We're thrilled to announce that Team Somehow clinched the top prize
            in the Blockchain domain at the Bit n Build Hackathon organized by
            GDSC CRCE & GDSC UMIT on January 28th-29th.
          </p>
        </section>

        <section>
          <h3>Project: Three Bricks</h3>
          <p>
            We developed a Decentralized Real Estate Property Transfer Platform
            using React on the frontend and Polygon, Hardhat, and Firebase on
            the backend.
          </p>
        </section>

        <section>
          <h3>Our Amazing Team</h3>
          <p>
            Special thanks to our outstanding team members: Arya Nair, Vinay
            Kanse and Vatsal Sanchala whose dedication was instrumental in our
            success.
          </p>
        </section>

        <section>
          <h3>Guidance from Palak Mantry</h3>
          <p>
            We extend our gratitude to Palak Mantry for her invaluable guidance
            and motivation throughout the journey, which culminated in a
            remarkable final presentation.
          </p>
        </section>
      </div>
    ),
    media: [
      process.env.PUBLIC_URL + "/travel/bit-n-build/2",
      process.env.PUBLIC_URL + "/travel/bit-n-build/1",
      process.env.PUBLIC_URL + "/travel/bit-n-build/3",
    ],
    badges: [
      {
        icon: "trophy",
        text: "1st Place",
      },
    ],
    latitude: 19.0444887,
    longitude: 72.8200037,
    zoom: 13,
    speed: 20,
  },
  {
    id: "clash-of-codes",
    heading: "Clash of Codes",
    initialSlide: 1,
    content: (
      <div>
        <section>
          <p>
            inspectElements secured the first runner-up position at the Clash of
            Codes event at Dwarkadas J. Sanghvi College of Engineering with our
            project, Apni Bachat.
          </p>
        </section>
        <section>
          <h3>Project: Apni Bachat</h3>
          <p>
            Apni Bachat, our decentralized banking application, offers inclusive
            lending options. Thanks to our talented team, including Eshan
            Trivedi, Aryan Parmar, and Kunal Chaturvedi, for their hard work.
          </p>
        </section>
        <section>
          <h3>Inspiration from Three Money</h3>
          <p>
            Our project was inspired by Team Somehow's (Vinay Kanase and Arya
            Nair) Three Money, and we're grateful for their contributions.
          </p>
        </section>
        <section>
          <h3>An Incredible Learning Experience</h3>
          <p>
            We learned and received guidance from industry expert Jetso Analin
            during the event.
          </p>
        </section>
        <section>
          <h3>Thanks to the Organizers</h3>
          <p>
            We appreciate the efforts of DJSCE IETE and DJSCE Trinity for
            hosting the event. Looking forward to future collaborations!
          </p>
        </section>
      </div>
    ),
    media: [
      process.env.PUBLIC_URL + "/travel/clash-of-codes/3",
      process.env.PUBLIC_URL + "/travel/clash-of-codes/2",
      process.env.PUBLIC_URL + "/travel/clash-of-codes/1",
    ],
    badges: [
      {
        icon: "trophy",
        text: "1st Runner Up",
      },
      {
        icon: "medal",
        text: "1st Place in Blockchain Domain",
      },
    ],
    latitude: 19.1071059,
    longitude: 72.8371074,
    zoom: 12,
    speed: 10,
  },
  {
    id: "hackerstellar",
    heading: "Hackerstellar",
    initialSlide: 1,
    content: (
      <div>
        <section>
          <p>
            We are excited to announce our first-place win in the blockchain
            category at 'Hackerstellar,' a 24-hour hackathon organized by CSI -
            KJSCE Student's Chapter at KJ Somaiya College of Engineering,
            Vidyavihar.
          </p>
        </section>
        <section>
          <h3>Introducing 'Three Supply'</h3>
          <p>
            Our winning project, 'Three Supply - A Plug and Play Supply Chain
            Solution,' features batch minting using ERC721a, QR-based reverse
            logistics, Aadhar QR KYC, escrow for payments, regulator approval,
            and more.
          </p>
        </section>
        <section>
          <h3>Team Effort</h3>
          <p>
            Credit for this success goes to our incredible team. Arya Nair
            handled integration and backend development, Vinay Kanse led
            frontend development, and Pargat Singh Dhanjal contributed as a
            frontend developer and designer.
          </p>
        </section>
        <section>
          <h3>Mentor's Impact</h3>
          <p>
            Special thanks to Jetso Analin, our mentor, for his invaluable
            insights into smart contracts and the web3 ecosystem.
          </p>
        </section>
        <section>
          <h3>Thanks to the Judges</h3>
          <p>
            We extend our gratitude to the judging panel from StoneX Group Inc.
            for recognizing our hard work and dedication.
          </p>
        </section>
      </div>
    ),
    media: [
      process.env.PUBLIC_URL + "/travel/hackerstellar/3",
      process.env.PUBLIC_URL + "/travel/hackerstellar/1",
      process.env.PUBLIC_URL + "/travel/hackerstellar/2",
      process.env.PUBLIC_URL + "/travel/hackerstellar/4",
    ],
    badges: [
      {
        icon: "trophy",
        text: "1st Place in Blockchain Domain",
      },
    ],
    latitude: 19.0742617,
    longitude: 72.8996967,
    zoom: 14,
    speed: 20,
  },
  {
    id: "kavach-2023",
    heading: "Kavach 2023",
    initialSlide: 1,
    content: (
      <div>
        <section>
          <p>
            Another National Level Hackathon, this time took us to Noida, India
          </p>
        </section>
        <section>
          <h3>Project: Chakra View</h3>
          <p>
            A mega tool used to monitor ground officers deployed in security
            arrangement. AI to rate bandobast. Location is tracked using
            multiple methods like NFC, QR Code, BLE or GPS. Fallback methods.
            100% accuracy. Report generation, officer routing, custom auto
            assignment to duty, and tracking officer health scores.
          </p>
        </section>
        <section>
          <h3>Media Coverage</h3>
          <p>
            Our project was featured in the news. We were interviewed by the
            media and were featured in the newspaper.{" "}
            <a href="https://www.jagran.com/uttar-pradesh/noida-ncr-noida-mumbai-college-of-engineering-student-made-chakravyuh-app-for-policeman-duty-23496616.html">
              Read Article
            </a>
          </p>
        </section>
        <section>
          <h3>Our Team</h3>
          <p>
            I would like to thank my teamates Aditya Pai, Harshal Dave, Soham
            Bhoir, Harshal Dulera and Tanvi Panchal for their dedication and
            hardwork.
          </p>
        </section>
      </div>
    ),
    media: [
      process.env.PUBLIC_URL + "/travel/kavach-2023/2",
      process.env.PUBLIC_URL + "/travel/kavach-2023/4",
      process.env.PUBLIC_URL + "/travel/kavach-2023/1",
      process.env.PUBLIC_URL + "/travel/kavach-2023/3",
    ],
    badges: [
      {
        icon: "trophy",
        text: "Top 5 Finalist",
      },
    ],
    latitude: 28.4726056,
    longitude: 77.4859896,
    zoom: 5,
    speed: 10,
  },
  // edelweiss hackathon here (TODO)
  {
    id: "junction-2023",
    heading: "Junction 2023",
    initialSlide: 1,
    content: (
      <div className="junction-2023-section">
        <>
          <div className="junction-2023-section">
            <p>
              Triumphed at the Aalto University's Resource Revolution track at
              Junction 2023 in Helsinki with team members Jay Malave, Simon
              Farshid, Daria Kazmia, and Imed Eddin.
            </p>
            <h3>Innovative Solution: ReMaskIt</h3>
            <p>
              Developed ReMaskIt, an Instagram filter and an app for recycling
              education, using advanced technologies like Google MLKit, React
              Native, Flask and OpenAI GPT-4 Vision.{" "}
              <a href="https://eu.junctionplatform.com/projects/junction-2023/view/654ea5c65817330aad38712c">
                Project Link.
              </a>
            </p>
            <h3>Unforgettable Experiences</h3>
            <p>
              Valuable visits to Huawei Labs, Veikkaus office, and Aalto
              University. Heartfelt gratitude to mentors and the Junction team
              for their support.
            </p>
            <h3>Acknowledgments</h3>
            <p>
              Special thanks to Rushang for his guidance and support throughout
              the journey.
            </p>
            <h3>Reflecting on the Journey</h3>
            <p>
              A momentous experience emphasizing innovation, teamwork, and
              sustainability.{" "}
              <a href="https://avp.aalto.fi/unfiltered-genius-a-junction-team-brings-recycling-to-instagram/">
                Interview conducted by Aalto University.
              </a>
            </p>
          </div>
        </>
      </div>
    ),
    media: [
      process.env.PUBLIC_URL + "/travel/junction-2023/2",
      process.env.PUBLIC_URL + "/travel/junction-2023/1",
      process.env.PUBLIC_URL + "/travel/junction-2023/3",
      process.env.PUBLIC_URL + "/travel/junction-2023/4",
      process.env.PUBLIC_URL + "/travel/junction-2023/5",
      process.env.PUBLIC_URL + "/travel/junction-2023/6",
      process.env.PUBLIC_URL + "/travel/junction-2023/7",
      process.env.PUBLIC_URL + "/travel/junction-2023/8",
      process.env.PUBLIC_URL + "/travel/junction-2023/9",
    ],
    badges: [
      {
        icon: "medal",
        text: "Winner Aalto University Track",
      },
    ],
    latitude: 60.1699,
    longitude: 24.9384,
    zoom: 5,
    speed: 20,
  },
  {
    id: "eth-india",
    heading: "ETH India",
    initialSlide: 1,
    content: (
      <div>
        <section>
          <p>
            We won at EthIndia 2023, a global hackathon featuring 2000+ coders
            from 35+ countries. Our project, 3-Transform, a web2 to web3 tool
            for beginners, clinched the top spot, showcasing the power of
            innovation and collaboration. You can look into the project here-
            <a href="https://devfolio.co/projects/transform-014b">
              Project Link
            </a>
          </p>
        </section>
        <section>
          <h3>Multiple Bounties</h3>
          <p>
            Not only did we win the main event, but we also secured multiple
            bounties from sponsors like Scroll, Filecoin Labs, and Okto.
          </p>
        </section>
        <section>
          <h3>Team Effort</h3>
          <p>
            Huge shoutout to my incredible teammates Arya Nair, Pargat Singh
            Dhanjal, and Vinay Sitaram Kanse. 🙌 Their dedication and expertise
            were instrumental in our success. Special thanks to our seniors
            Neelansh Mathur and Burhanuddin Rangwala, whose guidance was
            invaluable.
          </p>
        </section>
      </div>
    ),
    media: [process.env.PUBLIC_URL + "/travel/eth-india/1"],
    badges: [
      {
        icon: "medal",
        text: "Winner",
      },
    ],
    longitude: 77.594566,
    latitude: 12.971599,
    zoom: 5,
    speed: 10,
  },
  {
    id: "datathon-2024",
    heading: "Datathon 2024",
    initialSlide: 1,
    content: (
      <div>
        <section>
          <h3>The Hackathon</h3>
          <p>
            Datathon 2024 was a data-centric hackathon, bringing together some
            of the most innovative minds to tackle challenges in the Generative
            AI domain. Our team, <strong>Team Somehow</strong>, participated in
            this incredible event and emerged victorious in the Generative AI
            category!
          </p>
        </section>

        <section>
          <h3>The Project: Money Mitra</h3>
          <p>
            Alongside Gaurish Baliga and Vinay Sitaram Kanse, we built{" "}
            <strong>Money Mitra</strong>—an all-in-one finance management tool
            that simplifies managing finances. Money Mitra leverages generative
            AI to provide personalized insights and recommendations, making
            financial management accessible and intuitive for users. You can
            learn more about the project and watch the pitch video on{" "}
            <a href="https://devfolio.co/projects/money-mitr-e2fc">Devfolio</a>.
          </p>
        </section>

        <section>
          <h3>Gratitude to the Organizers</h3>
          <p>
            We extend our heartfelt thanks to Datazen for organizing such an
            amazing event that challenged and motivated us to push our
            boundaries in the field of Generative AI.
          </p>
        </section>
      </div>
    ),
    media: [process.env.PUBLIC_URL + "/travel/datathon-2024/1"],
    badges: [
      {
        icon: "trophy",
        text: "Winner Generative AI Domain",
      },
    ],
    latitude: 19.0742617,
    longitude: 72.8996967,
    zoom: 13,
    speed: 20,
  },
  {
    id: "ethglobal-singapore",
    heading: "ETHGlobal Singapore",
    initialSlide: 1,
    content: (
      <div>
        <section>
          <h3>The Hackathon</h3>
          <p>
            At ETHGlobal Singapore, we proudly showcased <strong>Judg3</strong>,
            a decentralized pairwise voting system inspired by the
            "This-or-That" game. Blockchain technology ensures transparency,
            security, and accuracy in voting.
          </p>
        </section>

        <section>
          <h3>The Project: Judg3</h3>
          <p>
            Judg3 supports applications like hackathon judging, hiring, and
            prediction games, using the Bradley-Terry model for precise pairwise
            comparisons. Built with{" "}
            <strong>
              React.js, Hardhat, Flow, Dynamic, Worldcoin, and Django
            </strong>
            . Check out the project on Devfolio!
          </p>
        </section>

        <section>
          <h3>Gratitude to the Team</h3>
          <p>
            Huge thanks to my teammates Gaurish Baliga, Pargat Singh Dhanjal,
            Rahul Dandona, and Vinay Sitaram Kanse for their hard work and
            dedication.
          </p>
        </section>

        <section>
          <h3>Gratitude to the Organizers</h3>
          <p>
            Thank you, ETHGlobal, for organizing an incredible event that pushed
            us to innovate and grow.
          </p>
        </section>
      </div>
    ),
    media: [process.env.PUBLIC_URL + "/travel/ethglobal-singapore/1"],
    badges: [
      {
        icon: "medal",
        text: "ETHGlobal Singapore Showcase",
      },
    ],
    latitude: 1.282302,
    longitude: 103.858528,
    zoom: 5,
    speed: 10,
  },
];
