import "./responsivelocationcard.css";
// import { BiInfoCircle } from "react-icons/bi";
// import { Parallax } from "react-scroll-parallax";
import LazyImage from "../../LazyImage/LazyImage";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards, Navigation } from "swiper";

// import { GrFormNext, GrFormPrevious } from "react-icons/gr"

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-cards";
import ReactPlayer from "react-player/lazy";
import HackathonBadge, { HackathonBadgeType } from "../../Badge/HackathonBadge/HackathonBadge";

type Props = {
  heading: string;
  content: React.ReactNode;
  media: string[];
  speed: number;
  initialSlide?: number
  badges?: HackathonBadgeType[];
};

const ResponsiveLocationCard = ({
  heading,
  content,
  speed,
  badges,
  initialSlide = 0,
  media,
}: Props) => {

  const shimmerStyles = Object.assign(
    { height: 200, width: 350 },
  );

  return (
    // <Parallax speed={speed}>
    <div className="responsive-location-card-container glass-effect-1">
      {media.length > 0 && <div className="img-container">
        <div
          style={{
            height: 250,
            padding: 10,
            display: "flex",
            justifyContent: "center",
            width: "100%"
          }}
        >
          <Swiper
            slidesPerView={"auto"}
            effect={"cards"}
            defaultValue={3}
            centeredSlides={true}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[EffectCards, Navigation]}
            initialSlide={media.length > 1 ? initialSlide : 0}
            onSlideChange={slider => {
              // console.log("slide change", slider.previousIndex, slider.activeIndex)
            }}
            navigation={{
              nextEl: ".next-swiper-location-card",
              prevEl: ".prev-swiper-location-card"
            }}
          >
            {media.map((link) => (
              <SwiperSlide key={link}>
                {(link.includes("mp4")) ?
                  <ReactPlayer playing muted url={link} loop fallback={<div style={shimmerStyles} className="shimmer"></div>} />
                  :
                  <LazyImage height={250} src={link} />
                }
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>}
      <div className="responsive-location-card-content-container" style={{ marginTop: 5 }}>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
          <div className="responsive-location-card-content-title">{heading}</div>

        </div>
        <div style={{}}>
          {badges && badges.length > 0 && badges.map((badge, i) => (
            <HackathonBadge text={badge.text} icon={badge.icon} key={i} />
          ))}
        </div>

        <div className="responsive-location-card-content-preview">{content}</div>

        <div
          style={{
            display: "flex",
            marginTop: 15,
          }}
        >
          {/* <div
              className="badgeGlassButton glass-effect-1"
              onClick={() => {
                const projectElement = document.getElementById("ChakraView");
                if (projectElement) {
                  projectElement.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              <BiInfoCircle className="icon" />
              <span style={{ marginLeft: 20 }}>Project Details</span>
            </div> */}
        </div>
      </div>
    </div>
    // </Parallax >
  );
};

export default ResponsiveLocationCard;