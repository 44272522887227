import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-cards";
import ReactPlayer from "react-player/lazy";
import LazyImage from "../../LazyImage/LazyImage";
import useWindowDimensions from "../../../hooks/useWindowDimentions";

type Props = {
  media: string[]
  initialSlide?: number
}

const SwiperMediaContainer = ({ media, initialSlide }: Props) => {
  const { width } = useWindowDimensions()

  const shimmerStyles = Object.assign(
    { height: 200, width: width > 785 ? 350 : 250 },
  );

  return (
    <div
      style={{
        height: 200,
        maxWidth: width > 785 ? 350 : 250,
      }}
    >
      <Swiper
        slidesPerView={"auto"}
        effect={"cards"}
        defaultValue={3}
        centeredSlides={true}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[EffectCards]}
        initialSlide={media.length > 1 ? initialSlide : 0}
        onSlideChange={slider => {
          // console.log("slide change", slider.previousIndex, slider.activeIndex)
        }}
      >
        {media.map((link) => (
          <SwiperSlide key={link}>
            {(link.includes("mp4")) ?
              <ReactPlayer playing muted url={link} loop fallback={<div style={shimmerStyles} className="shimmer"></div>} />
              :
              <LazyImage src={link} />
            }
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default SwiperMediaContainer