const volunteeringData = [
  {
    companyLogo:
      "https://github.com/kjsce-codecell/kjsce-codecell.github.io/blob/master/static/favicons/android-chrome-512x512.png?raw=true",
    companyName: "KJSCE codecell",
    companyTime: "March 2021 - September 2022",
    role: "Technical Team Member",
    description: (
      <ul>
        <li>
          Conducted workshops on the following topics - Competitive Programming, Python, Django,
          ReactJS, Ex- pressJS, MongoDB, Firebase authentication.
        </li>
        <li>
          Lead a team of 3 to build a website for the annual technical fest at KJSCE.{' '}
          <a href="https://pettiboy.github.io/Abhiyantriki_2021/" target="_blank" rel="noreferrer">(Demo)</a>
        </li>
        <li>
          Built an application portal for students to apply and be a part of codecell.{' '}
          <a href="http://join-kjscecodecell-com.pettiboy.com/" target="_blank" rel="noreferrer">(Demo)</a>
        </li>
        <li>
          Authored and tested CP questions for contests (Codespree and Crackathon) hosted on CodeChef.
        </li>
        <li>
          Collaborated to teach 150+ passionate students the MERN stack beginning from the basics
          of web development.{' '}
          <a href="https://www.youtube.com/playlist?list=PL4-KBDabApPBcHHj0wfTkDz0AfgFm1alq" target="_blank" rel="noreferrer"> (Video Lectures)</a>
        </li>
      </ul>
    ),
    link: "https://www.kjscecodecell.com/",
  },
  {
    companyLogo:
      process.env.PUBLIC_URL + "/logos/maalvo.png",
    companyName: "Maalvo Learners",
    companyTime: "June 2022",
    role: "Frontend Development Instructor",
    description: (
      <ul>
        <li>Taught basics of VScode</li>
        <li>Explained basics of the DOM, HTML, CSS and JS</li>
      </ul>
    ),
    link: "https://learners.maalvo.com/",
  },
  {
    companyLogo: "https://images.collegedunia.com/public/college_data/images/logos/14786759201.jpg",
    companyName: "Shah And Anchor Kutchhi Engineering College",
    companyTime: "June 2022",
    role: "Django Instructor",
    description: (
      <ul>
        <li>
          Interacted with a highly engaged audience of <b>150+ students</b>,
          fostering an environment of active learning and participation.
        </li>
        <li>
          Received positive feedback for the workshop's effectiveness in
          knowledge transfer and student engagement.
        </li>
        <li>
          Shared valuable <a href="https://github.com/pettiboy/django-workshop" target="_blank" rel="noreferrer">study materials</a> to
          help participants review and reinforce their learning.
        </li>
      </ul>
    ),
    // link: "https://learners.maalvo.com/",
  },
];

export default volunteeringData;
