import { ParallaxProvider } from "react-scroll-parallax";
import Intro from "./components/Intro/Intro";
import Projects from "./components/Projects/Projects";
// import Blogs from "./components/Blogs/Blogs";
import WorkExperience from "./components/WorkExperience/WorkExperience";
// import VideoTutorials from "./components/VideoTutorials/VideoTutorials";
import Footer from "./components/Footer/Footer";

// particleJS
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { Engine } from "tsparticles-engine";

import particleJsOptions from "./constants/particleJsConfig";
import Volunteering from "./components/Volunteering/Volunteering";
import { MapContextProvider } from "./contexts/MapContext";
import MapContainer from "./components/MapContainer/MapContainer";
// import ResponsiveMapContainer from "./components/MapContainer/ResponsiveMapContainer";
import useWindowDimensions from "./hooks/useWindowDimentions";
import ResponsiveMapContainer from "./components/MapContainer/ResponsiveMapContainer";

function App() {
  const { width } = useWindowDimensions()

  const particlesInit = async (main: Engine) => {
    await loadFull(main);
  };

  return (
    <ParallaxProvider>
      <MapContextProvider>
        <Particles
          id="tsparticles"
          options={particleJsOptions}
          init={particlesInit}
        />

        <Intro />
        {/* <Test /> */}

        <Projects />
        <WorkExperience />
        <Volunteering />
        {width > 780 ? <MapContainer /> : <ResponsiveMapContainer />}
        {/* <MapContainer /> */}
        {/* <Blogs /> */}
        {/* <VideoTutorials /> */}

        {width > 780 && <Footer />}
      </MapContextProvider>
    </ParallaxProvider>
  );
}

export default App;
