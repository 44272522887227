import React, { createContext, useState } from 'react';

interface MapContextProps {
  latitude: number;
  longitude: number;
  zoom: number;
}

interface MapContextDataProps {
  flyToLocationData: MapContextProps;
  updateFlyToLocationData: (data: MapContextProps) => void;
}

export const MapContext = createContext<MapContextDataProps>({
  flyToLocationData: {
    latitude: 0,
    longitude: 0,
    zoom: 0
  },
  updateFlyToLocationData: () => { }
});

export const MapContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [flyToLocationData, setFlyToLocationData] = useState<MapContextProps>({
    latitude: 0,
    longitude: 0,
    zoom: 0
  });

  const updateFlyToLocationData = (data: MapContextProps) => {
    // console.log('updateFlyToLocationData', data);
    setFlyToLocationData({
      ...flyToLocationData,
      ...data
    });
  };

  const contextValue: MapContextDataProps = {
    flyToLocationData,
    updateFlyToLocationData
  };

  return <MapContext.Provider value={contextValue}>{children}</MapContext.Provider>;
};
