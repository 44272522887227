import { useEffect, useRef, useContext, MutableRefObject } from 'react';
import { Map, MapRef } from 'react-map-gl';
import { MapContext } from '../../../contexts/MapContext';
import { defaultInitialView, mapBoxGLAccessToken, mapStyle } from '../../../constants/map';
import { Marker } from 'react-map-gl';
import CurvedLine from './CurvedLine/CurvedLine';
import { locationCardData } from '../data';

type Props = {
  height?: string;
  width?: string;
  borderRadius?: number;
};

const TravelMap = ({
  height = '60vh', width = '50vw', borderRadius = 13
}: Props) => {
  var mapRef = useRef<MutableRefObject<MapRef | null>>(null);

  const { flyToLocationData } = useContext(MapContext)

  const mapInitialViewState = {
    ...defaultInitialView,
    bearing: 0,
    pitch: 0,
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  };

  const handleMoveToFixedLocation = (fixedLongitude: number, fixedLatitude: number, zoom: number) => {
    if (mapRef === null) return;
    // @ts-ignore
    const map = mapRef.getMap();
    if (map) {
      map.flyTo({
        center: [fixedLongitude, fixedLatitude],
        zoom: zoom,
        speed: 1.6
      });
    }
  };

  useEffect(() => {
    if (flyToLocationData.latitude !== 0 && flyToLocationData.longitude !== 0) {
      handleMoveToFixedLocation(flyToLocationData.longitude, flyToLocationData.latitude, flyToLocationData.zoom);
    }
  }, [flyToLocationData]);

  const homeCoords: [number, number] = [72.8777, 19.0760];

  const mapContainerStyles = {
    width: '100%',
    borderRadius: borderRadius
  };

  return (
    <div style={{ height: height, width: width, borderRadius: borderRadius }}>
      <Map
        initialViewState={mapInitialViewState}
        style={{
          ...mapContainerStyles,
          height: height,
        }}
        mapStyle={mapStyle}
        mapboxAccessToken={mapBoxGLAccessToken}
        renderWorldCopies={false}
        attributionControl={false}
        // @ts-ignore
        ref={map => (mapRef = map)}
      >
        {/* Marker marking home */}
        <Marker
          latitude={homeCoords[1]}
          longitude={homeCoords[0]}
          style={markerStyle}
          onClick={() => {

          }}
        >
          <img src={process.env.PUBLIC_URL + "icons/map/house.png"} width={30} alt="" />
        </Marker>

        {locationCardData.map((cardData, index) => (
          <div key={cardData.id + index}>
            <Marker
              latitude={cardData.latitude}
              longitude={cardData.longitude}
              style={markerStyle}
              onClick={() => {

              }}
            >
              <img src={process.env.PUBLIC_URL + "icons/map/pin.png"} width={20} alt="" />
            </Marker>
            <CurvedLine
              id={cardData.id}
              start={homeCoords}
              end={[cardData.longitude, cardData.latitude]}
              curveStyles={{
                "line-color": "#f99c1d", "line-width": 1,
              }}
            />
          </div>
        ))}
      </Map>
    </div>
  );
};

export default TravelMap;




const markerStyle = {
  cursor: 'pointer'
};