import { Parallax } from "react-scroll-parallax";

import "./card.css";

import { FaGithub, FaLaptop } from "react-icons/fa";
import { useEffect } from "react";
import useWindowDimensions from "../../hooks/useWindowDimentions";
import Badge, { BadgeIconsKeyType } from "../Badge/Badge";
import ButtonGlass from "../ButtonGlass/ButtonGlass";
import SwiperMediaContainer from "../utils/SwiperMediaContainer/SwiperMediaContainer";

type Props = {
  speed: number;
  heading: string;
  content: string;
  direction: "left" | "right";
  media: string[];
  initialSlide?: number;
  demoUrl?: string;
  githubUrl?: string;
  badges?: BadgeIconsKeyType[];
};

const Card = ({
  heading,
  speed,
  content,
  direction,
  media,
  initialSlide = 1,
  demoUrl,
  githubUrl,
  badges,
}: Props) => {
  const { width } = useWindowDimensions();

  useEffect(() => {
    return () => { };
  }, []);

  return (
    <div>
      <Parallax speed={speed}>
        <div
          className={
            direction === "right"
              ? "glass-effect-2 right-helper"
              : "glass-effect-2 left-helper"
          }
        >
          <SwiperMediaContainer media={media} initialSlide={initialSlide} />
        </div>
        <div className={"container " + direction}>
          <div className="content">
            <div
              className="glass-effect-2"
              style={{
                padding: 20,
              }}
            >
              {width < 600 && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <SwiperMediaContainer media={media} initialSlide={initialSlide} />
                </div>
              )}
              <h3>{heading}</h3>
              <p>{content}</p>

              <div className="card-footer">
                {githubUrl && (
                  <ButtonGlass
                    href={githubUrl}
                    icon={<FaGithub />}
                    text="code"
                  />
                )}
                {demoUrl && (
                  <ButtonGlass href={demoUrl} icon={<FaLaptop />} text="demo" />
                )}
              </div>

              {/* badge container */}
              <div className="card-badge-container">
                {badges?.map((badge, i) => (
                  <Badge text={badge} key={badge + i} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Parallax>
    </div>
  );
};

export default Card;
