import { BadgeIconsKeyType } from "../Badge/Badge";

interface projectsDataType {
  heading: string;
  content: string;
  media: string[];
  demoUrl?: string;
  githubUrl?: string;
  badges?: BadgeIconsKeyType[];
}

const projectsData: projectsDataType[] = [
  {
    heading: "Pointer Aid",
    content:
      "Given marks of completed assessments predicts the minimum marks required for upcoming assessments to maximise pointer(CGPA). This app has 37K+ views and over 3000+ active users.",
    media: [
      process.env.PUBLIC_URL + "/projects/pointer-aid/ss3",
      process.env.PUBLIC_URL + "/projects/pointer-aid/ss2",
      process.env.PUBLIC_URL + "/projects/pointer-aid/ss4",
      process.env.PUBLIC_URL + "/projects/pointer-aid/ss5",
      process.env.PUBLIC_URL + "/projects/pointer-aid/ss1",
    ],
    // githubUrl: "https://pointer-aid.pettiboy.com/?source=pettiboy.com",
    demoUrl: "https://pointer-aid.pettiboy.com/?source=pettiboy.com",
    badges: [
      "React",
      "TypeScript",
      // 'mui'
    ],
  },
  {
    heading: "Open source npm package for react",
    content:
      "Customizable Scroll Spy component for react which is Easy To Use and Lightweight with callback, typescript, auto-update URL hash and throttle support among others. 120k+ instals.",
    media: [
      process.env.PUBLIC_URL + "/projects/react-ui-scrollspy/ss1",
      process.env.PUBLIC_URL + "/projects/react-ui-scrollspy/banner",
      process.env.PUBLIC_URL + "/projects/react-ui-scrollspy/ss2",
    ],
    githubUrl: "https://github.com/pettiboy/react-ui-scrollspy",
    demoUrl: "https://pettiboy.github.io/react-ui-scrollspy/",
    badges: ["npm", "React", "TypeScript"],
  },
  {
    heading: "3 Transform",
    content:
      "A web2 to web3 transition tool offering a single-click solution for transforming websites, for learning and for businesses. Features an optimized backend runtime with Hardhat and shell scripting and LLM powered smart contract creation.",
    media: [
      process.env.PUBLIC_URL + "/projects/3-transform/ss1",
      process.env.PUBLIC_URL + "/projects/3-transform/ss2",
      process.env.PUBLIC_URL + "/projects/3-transform/ss3",
      process.env.PUBLIC_URL + "/projects/3-transform/ss4",
      process.env.PUBLIC_URL + "/projects/3-transform/ss5",
    ],
    githubUrl: "https://github.com/team-somehow/three-transform",
    demoUrl: "https://three-transform.pettiboy.com/",
    badges: ["React", "Firebase", "Solidity", "ExpressJS", "python"],
  },
  {
    heading: "Chakra View",
    content:
      "An end to end tool for monitoring ground personnel (police bandobast). A mobile app, web dashboard and NFC tags to accurately track and secure a bandobast.",
    media: [
      // process.env.PUBLIC_URL + "/projects/chakra-view/vid2.mp4",
      process.env.PUBLIC_URL + "/projects/chakra-view/2",
      process.env.PUBLIC_URL + "/projects/chakra-view/1",
      process.env.PUBLIC_URL + "/projects/chakra-view/3",
      process.env.PUBLIC_URL + "/projects/chakra-view/4",
      // process.env.PUBLIC_URL + "/projects/chakra-view/vid1.mp4",
    ],
    // githubUrl: "https://pointer-aid.pettiboy.com/?source=pettiboy.com",
    demoUrl:
      "https://www.jagran.com/uttar-pradesh/noida-ncr-noida-mumbai-college-of-engineering-student-made-chakravyuh-app-for-policeman-duty-23496616.html",
    badges: ["Django", "React", "React Native", "TypeScript"],
  },
  {
    heading: "Money Mitra",
    content:
      "A comprehensive WhatsApp-based financial management tool that enables users to track expenses, manage budgets, and split bills effortlessly through messages, images, or audio inputs. Provides personalized financial reports and advice, and tackles user input categorization for efficient actioning, enhancing user experience in personal finance.",
    media: [
      process.env.PUBLIC_URL + "/projects/money-mitra/ss1",
      process.env.PUBLIC_URL + "/projects/money-mitra/ss2",
      process.env.PUBLIC_URL + "/projects/money-mitra/ss3",
      process.env.PUBLIC_URL + "/projects/money-mitra/ss4",
      process.env.PUBLIC_URL + "/projects/money-mitra/ss5",
    ],
    // githubUrl: "https://pointer-aid.pettiboy.com/?source=pettiboy.com",
    demoUrl: "https://devfolio.co/projects/money-mitr-e2fc",
    badges: ["Django", "React", "TypeScript"],
  },
  {
    heading: "3 Bricks",
    content:
      "A decentralised platform using Polygon and NFTs to streamline the property buying and selling process, increase transparency and security, and minimise the use of intermediaries by simplifying the transfer of ownership. Includes a smart contract to perform the escrow process.",
    media: [
      process.env.PUBLIC_URL + "/projects/3-bricks/ss1",
      process.env.PUBLIC_URL + "/projects/3-bricks/demo1.mp4",
      process.env.PUBLIC_URL + "/projects/3-bricks/demo2.mp4",
      process.env.PUBLIC_URL + "/projects/3-bricks/ss2",
      process.env.PUBLIC_URL + "/projects/3-bricks/ss3",
    ],
    githubUrl: "https://github.com/team-somehow/3-bricks",
    demoUrl: "https://three-bricks-app.web.app/",
    badges: ["React", "Firebase", "Solidity"],
  },
  {
    heading: "Any Chains",
    content:
      "Managing the real time big data (records of 1500+ derivatives/sec) of futures and options chain to provide a light weight web app. Pre-processing unformatted raw data from TCP stream to the socket. Intuitive UI conveying maximum information. Algorithms to calculate 6+ greeks (technical statistics), responsive PWA, risk manager, etc. Visualization by graphs like bar, line, pie, candlesticks, etc. AI to predict price. In-built long strangle hedge strategy.",
    media: [
      process.env.PUBLIC_URL + "/projects/any-chains/2",
      process.env.PUBLIC_URL + "/projects/any-chains/1",
      // process.env.PUBLIC_URL + "/projects/any-chains/demo1.mp4",
      process.env.PUBLIC_URL + "/projects/any-chains/3",
    ],
    demoUrl: "https://www.youtube.com/watch?v=AreBDHiYldE",
    badges: ["React", "Firebase", "Solidity"],
  },
  {
    heading: "3 Chain",
    content:
      "A blockchain-based supply chain project that enhances trust, transparency, and efficiency by offering services like escrow for goods transfer, reverse logistics, quality assurance, location tracking, secure payments, and a user-friendly dashboard for key supply chain actors. Supplier -> Manufacturer -> Regulator -> Logistics -> Buyer",
    media: [
      process.env.PUBLIC_URL + "/projects/3-chain/ss6",
      process.env.PUBLIC_URL + "/projects/3-chain/demo.mp4",
      process.env.PUBLIC_URL + "/projects/3-chain/ss4",
      process.env.PUBLIC_URL + "/projects/3-chain/ss1",
      process.env.PUBLIC_URL + "/projects/3-chain/ss2",
      process.env.PUBLIC_URL + "/projects/3-chain/ss3",
      process.env.PUBLIC_URL + "/projects/3-chain/ss5",
    ],
    githubUrl: "https://github.com/team-somehow/three-chain",
    demoUrl: "https://three-chain.pettiboy.com/",
    badges: ["React", "Firebase", "Solidity"],
  },
  {
    heading: "3 Bank (Apni Bachat)",
    content:
      "A Decentralized Bank with decentralised credit score calculation. A system that is transparent, fair and financially inclusive. The Defi platform aims to establish a more secure more, user friendly and more scalable financial enviourment",
    media: [
      process.env.PUBLIC_URL + "/projects/apni-bachat/ss1",
      process.env.PUBLIC_URL + "/projects/apni-bachat/demo.mp4",
      process.env.PUBLIC_URL + "/projects/apni-bachat/ss4",
      process.env.PUBLIC_URL + "/projects/apni-bachat/ss2",
      process.env.PUBLIC_URL + "/projects/apni-bachat/ss3",
    ],
    githubUrl: "https://github.com/inspectElements/Apni-Bachat",
    // demoUrl: "https://three-chain.pettiboy.com/",
    badges: ["React", "Firebase", "Solidity"],
  },
  {
    heading: "Open source React Native npm package",
    content:
      "Functional, simple and customizable UI buttons for react native. Also contains loading functionality and automatically changes color for dual tone buttons. TypeScript support.",
    media: [
      process.env.PUBLIC_URL +
        "/projects/react-native-ui-buttons/ButtonOutline",
      process.env.PUBLIC_URL + "/projects/react-native-ui-buttons/ss-combined",
      process.env.PUBLIC_URL + "/projects/react-native-ui-buttons/ButtonSolid",
    ],
    githubUrl: "https://github.com/pettiboy/react-native-ui-buttons",
    demoUrl: "https://www.npmjs.com/package/react-native-ui-buttons",
    badges: ["React Native", "TypeScript", "npm", "iOS", "Android"],
  },
  {
    heading: "Java E-Commerce CLI",
    content:
      "A java package that provides e-commerce functionalities such as 2-Factor Authentication, Products Catalogue, Add to cart,Ordering etc. All from the CLI.",
    media: [
      process.env.PUBLIC_URL + "/projects/java-ecommerce-cli/ss-1",
      process.env.PUBLIC_URL + "/projects/java-ecommerce-cli/ss-2",
      process.env.PUBLIC_URL + "/projects/java-ecommerce-cli/ss-3",
    ],
    githubUrl: "https://github.com/pettiboy/java-ecommerce-sqlite",
    badges: ["Java", "sqllite", "csv"],
  },
  {
    heading: "Rectilinear Motion",
    content:
      "A python program that solves a simple mechanics problem using sympy!",
    media: [process.env.PUBLIC_URL + "/projects/rectilinear-motion/ss1"],
    githubUrl: "https://github.com/pettiboy/rectilinear-motion/",
    badges: ["python", "sympy"],
  },
  {
    heading: "React template for firebase",
    content:
      "A quick start Create React App template with TypeScript, react-router-dom, material-ui, gh-pages and firebase. With Google authentication, routing and deployment capabilities built in. With 400+ installs.",
    media: [
      process.env.PUBLIC_URL + "/projects/cra-template-typescript-firebase/ss1",
      process.env.PUBLIC_URL + "/projects/cra-template-typescript-firebase/ss3",
      process.env.PUBLIC_URL + "/projects/cra-template-typescript-firebase/ss2",
    ],
    githubUrl: "https://github.com/pettiboy/cra-template-typescript-firebase/",
    demoUrl: "https://pettiboy.github.io/cra-template-typescript-firebase/",
    badges: ["React", "TypeScript", "npm"],
  },
  {
    heading: "Tic Tac Toe",
    content:
      "Tic Tac Toe made using python and pygame. An implementation of the minimax algorithm.",
    media: [
      process.env.PUBLIC_URL + "/projects/tic-tac-toe/ss1",
      process.env.PUBLIC_URL + "/projects/tic-tac-toe/ss2",
      process.env.PUBLIC_URL + "/projects/tic-tac-toe/ss3",
      process.env.PUBLIC_URL + "/projects/tic-tac-toe/ss4",
      process.env.PUBLIC_URL + "/projects/tic-tac-toe/ss5",
    ],
    githubUrl: "https://github.com/pettiboy/tic-tac-toe",
    badges: ["python", "pygame"],
  },
  {
    heading: "Pseudo Notion",
    content:
      "Website similar to notion built using the MERN (MongoDB, ExpressJS, React, NodeJS) stack with authentication powered by firebase.",
    media: [
      process.env.PUBLIC_URL + "/projects/pseudo-notion/ss-dark1",
      process.env.PUBLIC_URL + "/projects/pseudo-notion/ss-light1",
    ],
    githubUrl: "https://github.com/pettiboy/pseudo-notion",
    demoUrl: "https://pettiboy.github.io/pseudo-notion",
    badges: ["MongoDB", "ExpressJS", "React", "Firebase", "MaterialUI"],
  },
];

export default projectsData;
