import { Parallax } from "react-scroll-parallax";
import useWindowDimensions from "../../hooks/useWindowDimentions";
import { FiLink } from "react-icons/fi";
import volunteeringData from "./data";

import "./Volunteering.css";
// import SwiperImageContainer from "../utils/SwiperImageContainer/SwiperImageContainer";

type Props = {};

const Volunteering = (props: Props) => {
  const { visualViewportWidth, width } = useWindowDimensions();

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 5,
      }}
    >
      <Parallax
        translateX={[visualViewportWidth + "px", 0 + "px", "easeOutQuint"]}
        style={{ display: "flex", marginBottom: width > 800 ? 50 : 0 }}
      >
        <h1 className="thumbsup">Volunteering</h1>
      </Parallax>

      <div className="volunteering__work">
        {volunteeringData.map((work, i) => (
          <Parallax
            speed={i % 2 !== 0 ? 5 : 15}
            key={i}
            className="volunteering__event"
          >
            <div className="volunteering__event__date glass-effect-3">
              <div className="volunteering__company_name">{work.companyName}</div>
              <div className="volunteering__company_time">{work.companyTime}</div>
              {/* <SwiperImageContainer
                images={[
                  process.env.PUBLIC_URL + '/projects/Abhiyantriki_2021/ss1',
                  process.env.PUBLIC_URL + '/projects/Abhiyantriki_2021/ss2',
                ]}
              /> */}
            </div>
            <div className="volunteering__event__content glass-effect-2">
              <div className="volunteering__event__title">{work.role}</div>
              <div className="volunteering__event__description">
                {work.description}
              </div>
              {work.link && (
                <>
                  <div className="volunteering__event__divider" />
                  <a
                    href={work.link}
                    className="volunteering__event__link_container"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <div>
                      <FiLink />
                    </div>
                    <div className="volunteering__event__link_text">
                      {work.link}
                    </div>
                  </a>
                </>
              )}
            </div>
          </Parallax>
        ))}
      </div>
    </div>
  );
};

export default Volunteering;
