import { Parallax } from "react-scroll-parallax";
import useWindowDimensions from "../../hooks/useWindowDimentions";
import Card from "../Card/Card";
import projectsData from "./data";
import "./timeline.css";
import { useState } from "react";
import ButtonGlass from "../ButtonGlass/ButtonGlass";
import { MdExpandMore } from "react-icons/md";

type Props = {};

const Projects = (props: Props) => {
  const { visualViewportWidth } = useWindowDimensions();
  const [numProjects, setNumProjects] = useState(6);

  const handleViewMore = () => {
    setNumProjects(projectsData.length);
  };

  return (
    <div
      style={{
        minHeight: "100vh",
      }}
    >
      <Parallax
        translateX={[
          visualViewportWidth + "px",
          visualViewportWidth / 2 - 80 + "px",
          "easeOutQuint",
        ]}
      >
        <h1>My Projects</h1>
      </Parallax>

      <div className="timeline">
        {projectsData.slice(0, numProjects).map((data, i) => (
          <Card
            key={data.heading + i}
            heading={data.heading}
            content={data.content}
            media={data.media}
            githubUrl={data.githubUrl}
            demoUrl={data.demoUrl}
            badges={data.badges}
            speed={i % 2 === 0 ? 5 : 15}
            direction={i % 2 === 0 ? "right" : "left"}
          />
        ))}
      </div>

      {numProjects < projectsData.length && (
        <Parallax speed={10}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <ButtonGlass text="View More" width={200} onClick={handleViewMore} icon={<MdExpandMore />} />
          </div>
        </Parallax>
      )}
    </div>
  );
};

export default Projects;
