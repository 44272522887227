import { Parallax } from "react-scroll-parallax";
import useWindowDimensions from "../../hooks/useWindowDimentions";
import { FiLink } from "react-icons/fi";
import workExp from "./data";

import "./WorkExperience.css";

type Props = {};

const WorkExperience = (props: Props) => {
  const { visualViewportWidth, width } = useWindowDimensions();

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 30,
      }}
    >
      <Parallax
        translateX={[-visualViewportWidth + "px", 0 + "px", "easeOutQuint"]}
        style={{ display: "flex", marginBottom: width > 800 ? 50 : 0 }}
      >
        <h1 className="thumbsup">My Work Experience</h1>
      </Parallax>

      <div className="timeline__work">
        {workExp.map((work, i) => (
          <Parallax
            speed={i % 2 !== 0 ? 5 : 15}
            key={i}
            className="timeline__event"
          >
            <div
              className={
                "timeline__event__icon glass-effect-2 " +
                "timeline__event__icon_" +
                i
              }
            >
              <img src={work.companyLogo} alt=""></img>
            </div>
            <div className="timeline__event__date glass-effect-3">
              <div className="timeline__company_name">{work.companyName}</div>
              <div className="timeline__company_time">{work.companyTime}</div>
            </div>
            <div className="timeline__event__content glass-effect-2">
              <div className="timeline__event__title">{work.role}</div>
              <div className="timeline__event__description">
                {work.description}
              </div>
              {work.link && (
                <>
                  <div className="timeline__event__divider" />
                  <a
                    href={work.link}
                    className="timeline__event__link_container"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <div>
                      <FiLink />
                    </div>
                    <div className="timeline__event__link_text">
                      {work.link}
                    </div>
                  </a>
                </>
              )}
            </div>
          </Parallax>
        ))}
      </div>
    </div>
  );
};

export default WorkExperience;
