import { FaJava, FaReact, FaFileCsv } from "react-icons/fa";
import {
  SiJavascript,
  SiTypescript,
  SiSympy,
  SiPython,
  SiMongodb,
  SiExpress,
  SiFirebase,
  SiMaterialui,
  SiHtml5,
  SiCss3,
  SiBootstrap,
  SiDjango,
  SiSolidity,
} from "react-icons/si";
import { RiNpmjsLine } from "react-icons/ri";
import { DiAndroid, DiApple, DiSqllite } from "react-icons/di";

import "./badge.css";

const textToIcon = {
  Java: <FaJava />,
  JavaScript: <SiJavascript />,
  TypeScript: <SiTypescript />,
  React: <FaReact />,
  "React Native": <FaReact />,
  Android: <DiAndroid />,
  iOS: <DiApple />,
  npm: <RiNpmjsLine />,
  sqllite: <DiSqllite />,
  csv: <FaFileCsv />,
  python: <SiPython />,
  sympy: <SiSympy />,
  pygame: <></>,
  MongoDB: <SiMongodb />,
  ExpressJS: <SiExpress />,
  Firebase: <SiFirebase />,
  MaterialUI: <SiMaterialui />,
  HTML: <SiHtml5 />,
  CSS: <SiCss3 />,
  Bootstrap: <SiBootstrap />,
  Django: <SiDjango />,
  Solidity: <SiSolidity />
};

export type BadgeIconsKeyType = keyof typeof textToIcon;

type Props = {
  text: BadgeIconsKeyType;
};

const Badge = ({ text }: Props) => {
  return (
    <div className="badge-container glass-effect-1">
      <div className="badge-icon">{textToIcon[text]}</div>
      <div className="badge-text">{text}</div>
    </div>
  );
};

export default Badge;
