import "./shimmer.css";

import { useState, useEffect } from "react";

type Props = {
  src: string;
  imageShowMethod?: "display" | "dimentions";
  width?: number | string;
  height?: number | string;
  className?: string;
  shimmerStyles?: React.CSSProperties;
  imgSytles?: React.CSSProperties;
};

const LazyImage = ({
  src,
  imageShowMethod = "dimentions",
  width = "300px",
  height = "200px",
  className,
  shimmerStyles,
  imgSytles,
}: Props) => {
  const [loading, setLoading] = useState(true);
  const [fallbackImageSrc, setFallbackImageSrc] = useState<string | undefined>(
    undefined
  );
  const [key, setKey] = useState(src);

  useEffect(() => {
    setLoading(true);
    setFallbackImageSrc(undefined);
    setKey(src);
  }, [src]);

  const afterLoad = () => {
    setLoading(false);
  };

  const onError = () => {
    // If webp fails to load, try loading png
    const pngSrc = src + ".png";
    setLoading(false);
    setFallbackImageSrc(pngSrc);
  };

  shimmerStyles = Object.assign(
    { height: height, width: width },
    shimmerStyles
  );

  let imageHiddenStyles: React.CSSProperties = {};
  if (imageShowMethod === "dimentions") {
    imageHiddenStyles = { height: 0, width: 0 };
  } else {
    imageHiddenStyles = { display: "none" };
  }

  return (
    <>
      {loading && <div style={shimmerStyles} className="shimmer"></div>}

      <img
        key={key}
        src={fallbackImageSrc || (src + ".webp")}
        style={!loading ? imgSytles : imageHiddenStyles}
        loading="lazy"
        onLoad={afterLoad}
        onError={onError}
        alt=""
        className={className}
      />
    </>
  );
};

export default LazyImage;
