import { Layer, LineLayer, Source } from "react-map-gl";
import { Feature, LineString } from "geojson";
import { along, length, lineString } from "@turf/turf";

type Props = {
  start: [number, number];
  end: [number, number];
  id: string;
  curveStyles: LineLayer["paint"];
};

const CurvedLine = ({ start, end, id, curveStyles }: Props) => {
  const lineDistance = length(lineString([start, end]));
  const arc = [];
  const steps = 500;

  for (let i = 0; i <= steps; i++) {
    const t = i / steps;
    const coordinates = along(lineString([start, end]), t * lineDistance).geometry.coordinates;
    arc.push(coordinates);
  }

  const curvedLine: Feature<LineString> = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: arc,
    },
  };

  return (
    <Source type="geojson" data={curvedLine}>
      <Layer id={id} source="route" type="line" paint={curveStyles} layout={{
        "line-cap": "round",
        "line-join": "round",
      }} />
    </Source>
  );
};

export default CurvedLine;
